import { complexQuery, getUserId } from "../../../Utils/Common/Library";
import { constants } from "../Dimension/Data/QueryConstants";

export function filterData(queryData, role) {
  if (!queryData) return [];

  if (role) {
    const userId = getUserId();
    queryData = queryData.filter((item) => {
      let type = [];
      console.log("items is: ", item);
      for (let i = 0; i < item.Dimensions.length; i++) {
        const { AppraiserID, ReviewerID } = item.Dimensions[i];
        if (userId === AppraiserID) {
          type.push("Appraiser");
        }
        if (userId === ReviewerID) {
          type.push("Reviewer");
        }
      }
      return type.includes(role === "R" ? "Reviewer" : "Appraiser");
    });
  }

  const statusArray = [
    "Assessment Initiated",
    "Appraiser Assessment Due",
    "Reviewer Assessment Due",
    "Moderator Action Due",
    "Appraisee Acceptance Due",
    "Assessment Closed",
  ];

  const dataMap = {};

  for (let i = 0; i < queryData.length; i++) {
    const doc = queryData[i];
    const documentId = doc.DocumentID;
    if (dataMap[documentId]) {
      const prevDoc = dataMap[documentId];
      const currDoc = doc;

      const prevStatus = prevDoc.CurrentStatus;
      const currStatus = currDoc.CurrentStatus;

      const prevIndex = statusArray.findIndex((s) => s === prevStatus);
      const currIndex = statusArray.findIndex((s) => s === currStatus);

      if (prevIndex !== -1 && currIndex !== -1) {
        if (currIndex > prevIndex) {
          dataMap[documentId] = currDoc;
        }
      }
    } else {
      dataMap[documentId] = doc;
    }
  }
  queryData = Object.values(dataMap);

  return queryData;
}

export async function fetchAllData(payloads, role) {
  try {
    const promises = payloads.map((payload) => complexQuery(payload));
    const results = await Promise.all(promises);
    const filteredResults = filterData(
      results.reduce((total, current) => {
        return [...total, ...current];
      }, []),
      role
    );
    console.log("All data fetched successfully:", filteredResults);
    return filteredResults;
  } catch (error) {
    console.error("One or more requests failed:", error);
    return [];
  }
}

export function generatePagination(totalRecords, relationType, userLimit) {
  const limit = userLimit;
  const totalPages = Math.ceil(totalRecords / limit);
  const paginationArray = [];

  for (let i = 0; i < totalPages; i++) {
    paginationArray.push({
      QueryName: constants.FindAllLineAndFunctionalEPPByAppraiserOrReviewerV2,
      otherData: {
        AppraiserUserID: getUserId(),
        RelationshipType: relationType,
        Limit: limit,
        Offset: i * limit,
      },
      RequiredFields: [],
    });
  }

  return paginationArray;
}

export function generateUserType(userId, dimensions) {
  let type = [];
  if (dimensions?.length > 0) {
    for (let i = 0; i < dimensions.length; i++) {
      const { AppraiserID, ReviewerID } = dimensions[i];
      if (userId === AppraiserID) {
        type.push("Appraiser");
      }
      if (userId === ReviewerID) {
        type.push("Reviewer");
      }
    }
  }

  type = [...new Set(type)];
  return type.join(",") || "N/A";
}
