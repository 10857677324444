import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getData } from "../../Utils/Common/Library";
import useProps from "../../Utils/CustomHooks/GetProps";
import useRender from "../../Utils/CustomHooks/RenderComponent";
import ClassicDropdown from "./ClassicDropdown";

const defaultProps = {
  label: null,
  menuItems: null,
  onChange: null,
  value: null,
  disable: null,
  styles: null,
  featureId: null,
  required: null,
  variant: null,
  mapper: {
    label: "",
    value: "",
  },
  apiResult: null,
  storeApiResult: null,
  size: null,
  filter: null,
  getSelectedDoc: null,
  fields: null, //["Header.*","Data.Name","Data.shortName"]
  getDataProps: {},
};

/*  Example
   <Dropdown
          label={"Dimesnions"}
          mapper={{
            label: "DimensionName",
            value: "TransactionID",
          }}
          featureId={DimensionFeatureId}
          value={GoalSettingsDimensions}
          apiResult={goalDimlist}
          storeApiResult={(val) => updateState(val, "goalDimlist")}
          onChange={(val) => updateState(val, "GoalSettingsDimensions")}
        />
*/

function Dropdown(props = defaultProps) {
  console.log("From drop: ", props);
  const [val, setValue] = useState(-1);
  var {
    label,
    menuItems,
    onChange,
    value,
    disable,
    styles,
    featureId,
    mapper,
    getAllStatus,
    apiResult,
    storeApiResult,
    variant,
    size,
    filter,
    required,
    getSelectedDoc,
    fields,
    id,
    group,
    getDataProps,
    dropdownType = "html",
    menuHeight = "280px",
  } = useProps(props);

  useEffect(() => {
    setValue(value);
  }, [value]);

  if (!featureId && menuItems && !menuItems[0]?.label) {
    menuItems = menuItems.map((ele) => ({ label: ele, value: ele }));
  }
  if (!getDataProps) getDataProps = {};
  const [list, setList] = useState(
    menuItems ? menuItems : [{ label: "Loading", value: "-1" }]
  );
  const [idToDocMap, setIdToDoc] = useState({});
  const [sentIdDocMap, setIdDocMap] = useState(false); //This is used as a flag to avoid re-render and send idToDocMap only once when loaded
  function filterData(item) {
    console.log("From Filter: ", item);
    if (filter) return filter(item);
    if (getAllStatus) return true;
    if (item.Status === "1") return true;

    return false;
  }
  // useEffect(() => {
  //   if (value && idToDocMap) {
  //     console.log("ID Doc map is: ",idToDocMap)
  //     onChange(value, idToDocMap);
  //     //setIdDocMap(true);
  //   }
  // }, []);
  useEffect(() => {
    if (featureId && !apiResult) {
      getData({ featureId, fields, ...getDataProps })
        .then((res) => {
          if (res.Data) {
            let { label, value } = mapper;
            let obj = idToDocMap;
            let arr = [];
            res.Data.filter(filterData).forEach((ele) => {
              obj[ele.TransactionID] = ele;
              arr.push({
                label: ele[label],
                value: ele[value],
              });
            });
            console.log("all object: ", obj);
            setIdToDoc({ ...obj });
            setList([...arr]);
            if (storeApiResult) storeApiResult(arr);
          } else {
            setList([
              {
                label: "No Data",
                value: -1,
              },
            ]);
          }
        })
        .catch((e) => {
          setList([
            {
              label: "Network Error",
              value: -1,
            },
          ]);
        });
    } else if (apiResult) {
      setList([...apiResult]);
    }
    if (value) {
      setValue(value);
    }
    if (
      JSON.stringify(list) !== JSON.stringify(menuItems) &&
      menuItems?.length > 0
    ) {
      setList(menuItems);
    }
  }, [menuItems]);
  if (useRender({ id, group })) return <></>;
  if (!list || (list?.length === 0 && !featureId)) return <>List Error</>;

  return dropdownType === "html" ? (
    <ClassicDropdown
      disable={disable}
      label={label}
      onChange={(e) => {
        let val = e.target.value;
        setValue(val);
        onChange(val, idToDocMap[val]);
      }}
      options={(list || []).map((item) => ({
        label: item.label || item,
        value: item.value || item,
      }))}
      required={required}
      styles={{ ...styles }}
      value={!val ? -1 : val}
      menuHeight={menuHeight}
    />
  ) : (
    <Box sx={{ minWidth: 120, ...styles }}>
      <FormControl size={size || ""} fullWidth variant={variant}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          disabled={disable}
          value={val === undefined ? -1 : val}
          style={{ zIndex: 10, padding: "0.5rem" }}
          onChange={(e) => {
            let val = e.target.value;
            setValue(val);
            onChange(val === -1 ? -1 : val, idToDocMap[val]);
          }}
          required={required}
        >
          <MenuItem value={-1}>{"Select a Value"}</MenuItem>
          {console.log("Label: ", list)}
          {list.map((ele) => (
            <MenuItem
              disabled={ele.disable}
              value={ele.value ? ele.value : ele.label}
            >
              {ele.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default Dropdown;
