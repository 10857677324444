import { Box, Paper, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Molecule from "../../MComponent/Molecule";
import Atomic from "../../AComponent/Atomic";
import { complexQuery } from "../../Utils/Common/Library";
import { constants } from "./Dimension/Data/QueryConstants";
import { PeriodMap } from "./Dimension/Phases/PeriodMap";

const { Table } = Molecule;
const { Dropdown } = Atomic;

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Paper sx={{ boxSizing: "border-box", borderRadius: "5px" }}>
        <Box sx={{ display: "flex", gap: "1em" }}>
          <Typography variant="subtitle2" fontWeight={600}>
            Average Score
          </Typography>
          <Typography variant="subtitle2">
            {payload[0].payload.average}
          </Typography>
        </Box>
        <br />
        <Box sx={{ display: "flex", gap: "1em" }}>
          <Typography variant="subtitle2" fontWeight={600}>
            Rating Label
          </Typography>
          <Typography variant="subtitle2">
            {payload[0].payload.label}
          </Typography>
        </Box>
        <br />
        <Box sx={{ display: "flex", gap: "1em" }}>
          <Typography variant="subtitle2" fontWeight={600}>
            Number Of Employees
          </Typography>
          <Typography variant="subtitle2">
            {payload[0].payload.users.length}
          </Typography>
        </Box>
      </Paper>
    );
  }

  return null;
};

function PeriodScoreDistribution({ DimensionID, UserID }) {
  const palette = useTheme().palette;

  const [loading, setLoading] = useState(false);
  const [epps, setEpps] = useState(null);
  const [filters, setFilters] = useState({
    planPeriod: "",
    reviewPeriod: "",
  });
  const [lineChartSelected, setLineChartSelected] = useState({});

  const planPeriods = useMemo(() => {
    if (!epps) return [];
    const planPeriodsMap = {};
    epps.forEach((epp) => {
      planPeriodsMap[epp?.EppData?.PlanPeriod || ""] = "";
    });
    return Object.keys(planPeriodsMap)
      .filter((key) => key)
      .sort();
  }, [epps]);

  const reviewPeriods = useMemo(() => {
    if (!epps) return [];
    if (!filters.planPeriod) return [];

    const eppsData = [...epps].filter(
      (epp) => epp.EppData.PlanPeriod === filters.planPeriod
    );

    let PeriodType = "";
    for (let ep = 0; ep < eppsData.length; ep++) {
      const epp = eppsData[ep];
      const assessments = epp.ParameterAssessments?.filter(
        (item) =>
          Object.keys(item).length > 0 && item.Data.DimensionID === DimensionID
      );
      for (let pa = 0; pa < assessments.length; pa++) {
        if (!PeriodType && assessments[pa].Data.ReviewPeriodType) {
          PeriodType = assessments[pa].Data.ReviewPeriodType;
          break;
        }
      }
      if (PeriodType) break;
    }

    if (!PeriodType) return [];

    return Object.entries(PeriodMap[PeriodType].values)
      .map(([value, label]) => ({ label, value }))
      .sort((a, b) => a.label < b.label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [epps, filters.planPeriod]);

  const records = useMemo(() => {
    const planPeriodFilter = filters.planPeriod;
    const reviewPeriodFilter = filters.reviewPeriod;

    if (!planPeriodFilter || !reviewPeriodFilter) return [];

    const eppRows = [...(epps || [])].filter(
      (epp) => epp.EppData.PlanPeriod === planPeriodFilter
    );

    const generateDataPointsFromRecords = (buckets) => {
      const scaleMap = {
        1: "Very Poor",
        2: "Poor",
        3: "Average",
        4: "Good",
        5: "Very Good",
      };

      function findAverage(value) {
        let total = 0;

        for (let i = 0; i < value.length; i++) {
          total += +parseFloat(value[i].PeriodAggregateScore) || 0;
        }

        return +total / value.length || 0;
      }

      const data = [];

      Object.entries(buckets)
        .sort((a, b) => (a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0))
        .forEach(([key, value2]) => {
          const low = parseFloat(key) - 0.5,
            high = parseFloat(key);

          data.push({
            range: `${low} - ${high}`,
            label: scaleMap[low] || scaleMap[high] || "Unrated",
            numEmployees: value2.length,
            users: value2,
            average: findAverage(value2),
          });
        });

      return data;
    };

    const buckets = {
      0.5: [],
      1: [],
      1.5: [],
      2: [],
      2.5: [],
      3: [],
      3.5: [],
      4: [],
      4.5: [],
      5: [],
    };

    eppRows.forEach((epp, index) => {
      const assessments = epp.ParameterAssessments?.filter(
        (item) =>
          Object.keys(item).length > 0 &&
          item.Data.ReviewPeriodUnit === reviewPeriodFilter &&
          item.Data.DimensionID === DimensionID
      );
      for (let l = 0; l < (assessments?.length || 0); l++) {
        const { PeriodAggregateScore } = assessments[l].Data;

        const keys = Object.keys(buckets).sort((a, b) =>
          a > b ? 1 : b > a ? -1 : 0
        );

        const AggregateScore = PeriodAggregateScore.find(
          (score) => score.ActorType === "Appraiser"
        )?.AggregateScore;

        for (let i = 0; i < keys.length; i++) {
          if (parseFloat(AggregateScore || "0.0000") <= parseFloat(keys[i])) {
            buckets[keys[i]].push({
              ...epp.EppData,
              ...epp.EppHeader,
              AppraiseeFullName: epp.AppraiseeFullName,
              PeriodAggregateScore: AggregateScore,
            });
            break;
          }
        }
      }
    });

    return generateDataPointsFromRecords(buckets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [epps, filters.planPeriod, filters.reviewPeriod]);

  useEffect(() => {
    if (!filters.planPeriod && planPeriods.length > 0) {
      setFilters((prevState) => ({
        ...prevState,
        planPeriod: planPeriods[planPeriods.length - 1],
      }));
    }
  }, [filters.planPeriod, planPeriods]);

  useEffect(() => {
    if (
      filters.planPeriod &&
      !filters.reviewPeriod &&
      reviewPeriods.length > 0
    ) {
      setFilters((prevState) => ({
        ...prevState,
        reviewPeriod: reviewPeriods[0].value,
      }));
    }
  }, [filters.planPeriod, filters.reviewPeriod, reviewPeriods]);

  useEffect(() => {
    if (UserID) {
      setLoading(true);
      complexQuery({
        RequiredFields: [
          {
            AppraiserID: UserID,
          },
          {
            DimensionID: DimensionID,
          },
        ],
        QueryName: constants.FindAssessmentsOfEPPByAppraiserIDAndDimensionID,
      })
        .then((res) => {
          setEpps(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <>Loading ...</>
  ) : (
    <>
      <Typography variant="h2" color={"primary"}>
        Score Distribution
      </Typography>
      <br />
      <Box display={"flex"} height={"80vh"} className="invisble-scroll">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: "0em",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                float: "right",
                margin: "1rem 0.5rem",
              }}
            >
              <Dropdown
                menuItems={planPeriods}
                label={"Select Plan Period"}
                onChange={(val) => {
                  setFilters({
                    planPeriod: val === -1 ? "" : val,
                    reviewPeriod: "",
                  });
                }}
                value={filters.planPeriod}
                size={"small"}
                styles={{ width: "10rem" }}
              />
              {filters.planPeriod && (
                <Dropdown
                  styles={{ width: "15rem" }}
                  menuItems={reviewPeriods}
                  label={"Select Assessment Period"}
                  onChange={(val) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      reviewPeriod: val === -1 ? "" : val,
                    }));
                  }}
                  value={filters.reviewPeriod}
                  size={"small"}
                />
              )}
            </Box>
            <br />
            <ComposedChart
              width={800}
              height={300}
              data={records}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="range"
                label={{
                  value: "Rating Scale",
                  position: "insideMiddle",
                  dy: 20,
                }}
                name="Rating Scale"
              />
              <YAxis
                label={{
                  value: "Number of Employees",
                  angle: -90,
                  position: "insideMiddle",
                  dx: -20,
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                type="monotone"
                dataKey="numEmployees"
                fill={`${palette.primary.main}`}
                onClick={(e) => {
                  setLineChartSelected((prevState) => ({
                    ...prevState,
                    Bar: e,
                  }));
                }}
              />
              <Line
                type="monotone"
                dataKey="numEmployees"
                stroke={`${palette.primary.main}`}
                activeDot={{
                  r: 7,
                  onClick: (data, index) => {
                    setLineChartSelected((prevState) => ({
                      ...prevState,
                      Line: index,
                    }));
                  },
                }}
              />
            </ComposedChart>
          </Box>
          {lineChartSelected["Bar"] && (
            <Box>
              <Table
                heading={["Username", "Score"]}
                rows={[
                  ...lineChartSelected["Bar"].payload.users.map((user) => {
                    return [
                      user.AppraiseeFullName || "N/A",
                      user.PeriodAggregateScore,
                    ];
                  }),
                ]}
                pageLimit={5}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default PeriodScoreDistribution;
