import { AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";
import "./DataGrid.css";
import { Paper, Typography } from "@mui/material";
import { v4 as uuid } from "uuid";
import { complexQuery, getData } from "../../Utils/Common/Library";
import "./License";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
function DataGrid2({
  heading,
  rows,
  rowsData,
  pageLimit = 10,
  featureId,
  query,
  rowToFieldMap,
  tableOptions,
  valueFormatter,
  getDataOptions,
  filterQueryData,
  saveServerData,
  minColumnWidth,
  onRowClicked = () => {},
}) {
  const defaultColDef = useMemo(() => {
    return {
      minWidth: minColumnWidth || 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
      resizeable: true,
    };
  }, []);

  if (!heading) {
    return (
      <div className="Flex">
        <Typography variant="h4" color={"red"}>
          Heading is required to display the table.
        </Typography>
      </div>
    );
  }

  if (!rowToFieldMap) {
    return (
      <div className="Flex">
        <Typography variant="h4" color={"red"}>
          Row to field map is required prop for AGgrid
        </Typography>
      </div>
    );
  }

  if (rowToFieldMap.length !== heading.length) {
    return (
      <div className="Flex">
        <Typography variant="h4" color={"red"}>
          Map length is not same as heading length
        </Typography>
      </div>
    );
  }

  function onGridReady(params) {
    if (!getDataOptions) getDataOptions = {};

    if (featureId) {
      getData({ featureId, ...getDataOptions })
        .then((response) => {
          console.log("response is: ", response.Data);
          params.api.applyTransaction({ add: response.Data });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      complexQuery({
        QueryName: query.QueryName,
        RequiredFields: [...query.RequiredFields],
        otherData: query.otherData,
      }).then((res) => {
        console.log("response is: ", res);
        saveServerData && saveServerData(res);
        if (filterQueryData) {
          params.api.applyTransaction({ add: filterQueryData(res) });
        } else {
          params.api.applyTransaction({ add: res });
        }
      });
    }
  }
  function getValue(event, fieldName) {
    const { value } = event;
    if (
      valueFormatter &&
      typeof fieldName === "string" &&
      valueFormatter[fieldName]
    ) {
      return valueFormatter[fieldName](value);
    }
    return value;
  }
  function generateColumns() {
    const mappings = [...rowToFieldMap];

    return mappings.map((mapping, index) => {
      const label = heading[index];
      const Component = mapping.Component;
      const props = mapping.props;
      console.log(label.length * 2, "LABELLENGTH");
      return {
        headerName: label,
        field: mapping.Component ? "" : mapping,
        hide: label.includes("HIDDEN") ? true : false,
        cellRendererFramework: Component
          ? (params) => <Component rowData={params.data} {...props} />
          : undefined,
        valueFormatter: (val) => getValue(val, mapping),
      };
    });
  }
  const sideBarDef = {
    toolPanels: [
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
    defaultToolPanel: "filters",
  };
  function generateRows() {
    return rows.map((row) => {
      const data = {};
      data.GeneratedRowID = uuid();
      console.log("Row filters is: ", row);
      row.forEach((value, index) => {
        if (!rowToFieldMap[index].Component) {
          let rowMap = rowToFieldMap[index];
          data[rowMap] = value;
        } else {
          data[rowToFieldMap[index]] = undefined;
        }
      });
      return data;
    });
  }

  return (
    <div className="ag-theme-alpine" style={{ maxHeight: 500, width: "100%" }}>
      <AgGridReact
        columnDefs={generateColumns()}
        rowData={
          rowsData ? rowsData : featureId || query ? undefined : generateRows()
        }
        onGridReady={
          featureId || query
            ? onGridReady
            : (params) => {
                const colIds = [];
                params.columnApi.getColumns().forEach((col) => {
                  colIds.push(col.getId());
                });
                console.log(colIds);
                params.columnApi.autoSizeColumns(colIds);
              }
        }
        defaultColDef={defaultColDef}
        domLayout="autoHeight"
        pagination={pageLimit ? true : false}
        paginationPageSize={pageLimit}
        sideBar={true}
        alwaysShowHorizontalScroll={true}
        onRowClicked={onRowClicked}
        gridOptions={{
          getRowClass: (params) => {
            return "pointer-row";
          },
        }}
      />
    </div>
  );
}

export default DataGrid2;
