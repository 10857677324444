import React, { useState, useEffect, useRef } from "react";
import "./ClassicDropdown.css";
import { Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

const ClassicDropdown = ({
  options,
  label,
  value,
  onChange,
  styles,
  disable,
  required,
  menuHeight,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdownBoxWidth, setDropdownBoxWidth] = useState("auto");
  const [dropdownMenuWidth, setDropdownMenuWidth] = useState("auto");

  const selectedOption = options.find((option) => option.value === value);
  const [selectedLabel, setSelectedLabel] = useState(
    selectedOption ? selectedOption.label : label || "Select an option"
  );

  const handleOptionClick = (option) => {
    onChange({ target: { value: option ? option.value : "" } });
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedOption) {
      setSelectedLabel(selectedOption.label);
    } else {
      setSelectedLabel(label || "Select an option");
    }
  }, [value, options, selectedOption]);

  // Calculate the widths on first render
  useEffect(() => {
    if (dropdownRef.current) {
      const defaultWidth = 200; // Minimum width
      const definedWidth =
        styles && styles.width ? parseInt(styles.width, 10) : defaultWidth;

      // Calculate DropdownBox width based on the label
      const tempBox = document.createElement("div");
      tempBox.style.position = "absolute";
      tempBox.style.visibility = "hidden";
      tempBox.style.whiteSpace = "nowrap";
      tempBox.style.padding = "0 12px"; // Add padding to match the dropdown box
      tempBox.innerHTML = selectedLabel;
      document.body.appendChild(tempBox);
      const boxWidth = tempBox.clientWidth + 35; // Adding extra padding
      document.body.removeChild(tempBox);

      // Calculate DropdownMenu width based on the longest option
      const optionWidths = options.map((option) => {
        const tempElement = document.createElement("div");
        tempElement.style.position = "absolute";
        tempElement.style.visibility = "hidden";
        tempElement.style.whiteSpace = "nowrap";
        tempElement.style.padding = "0 12px"; // Add padding to match the dropdown box
        tempElement.innerHTML = option.label;
        document.body.appendChild(tempElement);
        const width = tempElement.clientWidth + 35; // Adding extra padding
        document.body.removeChild(tempElement);
        return width;
      });

      // Also calculate width for "Select a Value"
      const selectValueTemp = document.createElement("div");
      selectValueTemp.style.position = "absolute";
      selectValueTemp.style.visibility = "hidden";
      selectValueTemp.style.whiteSpace = "nowrap";
      selectValueTemp.style.padding = "0 12px"; // Add padding to match the dropdown box
      selectValueTemp.innerHTML = "Select a Value";
      document.body.appendChild(selectValueTemp);
      const selectValueWidth = selectValueTemp.clientWidth + 35; // Adding extra padding
      document.body.removeChild(selectValueTemp);

      // Determine the maximum width needed
      const menuWidth = Math.max(...optionWidths, selectValueWidth);

      // Determine the final widths
      const finalBoxWidth = Math.max(definedWidth, boxWidth);
      setDropdownBoxWidth(`${finalBoxWidth}px`);
      setDropdownMenuWidth(`${Math.max(finalBoxWidth, menuWidth)}px`);
    }
  }, [options, selectedLabel, styles]);

  return (
    <div
      style={{
        position: "relative",
        zIndex: 10,
      }}
    >
      <div
        className={`custom-select-container ${
          disable ? "container-disable" : ""
        }`}
        ref={dropdownRef}
        style={{
          ...styles,
          width: styles.width ? styles.width : dropdownBoxWidth,
        }}
      >
        <div
          className={`custom-select ${isOpen ? "open" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
          style={{
            color:
              selectedLabel === (label || "Select an option")
                ? "#999999"
                : "black",
          }}
        >
          <Typography variant="p2">
            {selectedLabel + (required ? " *" : "")}
          </Typography>
          <KeyboardArrowDown />
        </div>
        {isOpen && (
          <ul
            className="custom-options"
            style={{
              width: styles.width
                ? styles.width >= dropdownMenuWidth
                  ? styles.width
                  : dropdownMenuWidth
                : dropdownMenuWidth,
              maxHeight: menuHeight,
            }}
          >
            <li
              className={
                value === -1 ? "custom-option-selected" : "custom-option"
              }
              onClick={() => handleOptionClick(-1)}
            >
              <Typography variant="p2">Select a Value</Typography>
            </li>
            {options.map((option, index) => (
              <li
                key={index}
                className={
                  option.value === value
                    ? "custom-option-selected"
                    : "custom-option"
                }
                onClick={() => handleOptionClick(option)}
              >
                <Typography variant="p2">{option.label}</Typography>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ClassicDropdown;
