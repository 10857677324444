import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppraiseeSnapshot from "./Widgets/AppraiseeSnapshot";
import DimensionScore from "./Widgets/DimensionScore";
import FeatureIDs from "../../../Utils/Common/FeatureIDs";
import {
  getData,
  getUserId,
  submitPayload,
} from "../../../Utils/Common/Library";
import { useDispatch } from "react-redux";
import { Actions } from "../../../Redux/Actions/Actiontypes";
import PlanScores from "./Widgets/PlanScores";
import OverallComments from "./Widgets/OverallComments";
import { getFinalPeriod } from "../Dimension/Phases/PeriodMap";
import Atomic from "../../../AComponent/Atomic";
import { WorkflowProcesser } from "../Dimension/WorkflowProcesser";
import AlertMessage from "../../../Utils/Messages/AlertMessage";
import EwModal from "../../../MComponent/Modal/Modal";

const { Button } = Atomic;

const AttendanceWidgetProps = {
  AttendanceWidgetDaysWorked: "",
  AttendanceWidgetHours: "",
  AttendanceWidgetPunctualityRecord: "",
  AttendanceWidgetPunctuality: "",
};

const AppraiseeSnapshotWidgetProps = {
  SnapShotWidgetEmployeeType: "",
  SnapShotWidgetDesignation: "",
  SnapShotWidgetRole: "",
  SnapShotWidgetEmployee: "",
  SnapShotWidgetLocation: "",
  SnapShotWidgetName: "",
  SnapShotWidgetGrade: "",
  SnapShotWidgetFunction: "",
};

const ScoresWidgetProps = {
  SummaryCommentsWidget: "",
  SummaryDimensionScores: "",
  SummaryPlanAggregateScoreWidget: "",
  SummaryMultiRaterScoreWidget: "",
  SummaryPlanAggregateScore: "",
  SummaryPlanModerateScore: "",
};

const AggregatePlanScoreProps = {
  AggregatePlanScore: "",
  AggregateFinalScore: "",
  AggregateScoreDisplay: "",
  AggregateRatingScaleID: "",
};

const ModeratedPlanScoreProps = {
  ModeratedPlanScore: "",
  ModeratedPlanScoreFinalScore: "",
};

const AssessorPlanScoreProps = {
  AssessorPlanScore: "",
  AssessorPlanRater: "",
  AssessorPlanFinalScore: "",
  AssessorPlanRatingScaleId: "",
};

const AdjustedPlanScoreProps = {
  AdjustedPlanScore: "",
  AdjustedPlanScoreDisplay: "",
  AdjustedPlanFinalScore: "",
  AdjustedPlanScoreRatingScaleID: "",
};

function SubmitAlert({ message, closeAlert, onSubmit, onCancel }) {
  const [loading, setLoading] = useState(false);
  return (
    <Box sx={{ padding: "0.5em" }}>
      <Typography variant="h6" fontWeight={"400"} width={"30vw"}>
        {message}
        {/* <b>{name}</b> */}
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2em",
        }}
      >
        <Button
          color={"info"}
          variant={"text"}
          title={"Cancel"}
          onClick={() => {
            closeAlert();
            onCancel();
          }}
        />
        <Button
          color={"info"}
          variant={"contained"}
          style={{ marginLeft: "1em" }}
          title={"Continue"}
          isLoading={loading}
          onClick={() => {
            setLoading(true);
            onSubmit().then(() => {
              setLoading(false);
              window.location.reload();
            });
          }}
        />
      </Box>
    </Box>
  );
}

const Summary = ({ EPP, handleTabEdit }) => {
  const dispatch = useDispatch();
  dispatch({ type: Actions.ADD_SIDE_COMPONENT, payload: null });

  const [FinalAssessments, setFinalAssessments] = useState({});

  const [document, setDocument] = useState({
    ...AppraiseeSnapshotWidgetProps,
    ...AttendanceWidgetProps,
    ...ScoresWidgetProps,
  });

  function getDimensionAssessmentDocs(DimensionID) {
    return getData({
      featureId: FeatureIDs.ParameterAsseessment,
      payload: {
        "Data.AppraiseeUserID": EPP.AppraiseeUserID,
        "Data.DimensionID": DimensionID,
        "Data.EmployeePerformancePlanID": EPP.TransactionID,
      },
      fields: ["Header.*", "Data.*", "Workflow.*"],
    });
  }

  useEffect(() => {
    getData({
      featureId: FeatureIDs.MasterPerformancePlan,
      payload: {
        "Header.TransactionID": EPP.MasterPerformancePlanID,
      },
    }).then((res) => {
      const MPP = res.Data[0];
      console.log(JSON.stringify(MPP), "JSON.stringify(MPP)");
      const SummaryDocument = {};

      for (let key in {
        ...AppraiseeSnapshotWidgetProps,
        ...AttendanceWidgetProps,
        ...ScoresWidgetProps,
        ...AggregatePlanScoreProps,
        ...ModeratedPlanScoreProps,
        ...AssessorPlanScoreProps,
        ...AdjustedPlanScoreProps,
      }) {
        SummaryDocument[key] = MPP[key];
      }

      console.log(JSON.stringify(SummaryDocument), "SUMMRY DOCUMENT");
      setDocument(SummaryDocument);
    });
  }, []);

  const [pendingWithAppraiser, setPendingWithAppraiser] = useState(false);
  const [confidentialPayload, setConfidentialPayload] = useState(null);
  const [actionError, setActionError] = useState(null);
  const [appraiserAssessments, setAppraiserAssessments] = useState([]);
  const [appraiserLoading, setAppraiserLoading] = useState(false);

  async function editEPP() {
    const edit = {
      TransactionID: EPP.TransactionID,
      EffectiveFromTimestamp: EPP.EffectiveFromTimestamp,
      RequestType: "1",
    };

    await submitPayload({
      featureId: FeatureIDs.EmployeePerformancePlan,
      edit,
      payload: {
        ...EPP,
      },
    });
  }

  async function editDimension(ParameterAssessment, ActionType) {
    const AssessmentDoc = {
      ParametersAndTargetDocumentID: "",
      Parameters: "",
      DimensionParameterAssessmentID: "",
      EventType: "",
      ReviewFromDate: "",
      PeriodAggregateScore: "",
      EmployeePerformancePlanID: "",
      WorkflowID: "",
      AppraiseeUserID: "",
      MasterPlanID: "",
      ReviewPeriodType: "",
      InterimReason: "",
      ReviewTillDate: "",
      DimensionID: "",
      ReviewPeriodUnit: "",
    };

    const WorkflowDoc = {
      ConversationID: "",
      WorkflowID: "",
      WorkflowStatus: "",
      WorkflowSteps: "",
    };

    const Header = {
        TransactionID: ParameterAssessment.TransactionID,
        EffectiveFromTimestamp: ParameterAssessment.EffectiveFromTimestamp,
      },
      Data = Object.keys(AssessmentDoc).reduce((total, current) => {
        total[current] = ParameterAssessment[current];
        return total;
      }, {});

    const Workflow = Object.keys(WorkflowDoc).reduce((total, current) => {
      total[current] = ParameterAssessment[current];
      return total;
    }, {});

    const workflowProcesser = new WorkflowProcesser(Workflow);
    let stepId = workflowProcesser.getCurrentStepID();
    const WorkflowID = Workflow.WorkflowID;
    let obj = {
      stepId,
      action: ActionType,
      WorkflowID,
    };

    let edit = {
      TransactionID: Header.TransactionID,
      EffectiveFromTimestamp: Header.EffectiveFromTimestamp,
    };
    console.log("Before sending: pms", Data, " ", obj, " ", edit, Workflow);

    return await submitPayload(
      ActionType
        ? {
            featureId: "19152",
            payload: Data,
            updateAction: obj,
            edit,
          }
        : {
            featureId: "19152",
            payload: Data,
            edit,
          }
    );
  }

  async function editConfidentialAssessment() {
    if (!confidentialPayload) return;
    console.log(confidentialPayload, "confidentialPayload");
    const FINAL_PAYLOAD = {
      EmployeePlanID: confidentialPayload.EmployeePlanID,
      PerformancePlanID: confidentialPayload.PerformancePlanID,
      AppraiseeUserID: confidentialPayload.AppraiseeUserID,
      AppraiserUserID: getUserId(),
      PerformanceSummary: confidentialPayload.PerformanceSummary,
      PotentialSummary: confidentialPayload.PotentialSummary,
      GrowthReadiness: confidentialPayload.GrowthReadiness,
      GrowthRoleID: confidentialPayload.GrowthRoleID,
      PromotionRecommendation: confidentialPayload.PromotionRecommendation,
      ExitRisk: confidentialPayload.ExitRisk,
    };

    const edit = {
      TransactionID: confidentialPayload.TransactionID,
      EffectiveFromTimestamp: confidentialPayload.EffectiveFromTimestamp,
      RequestType: "1",
    };

    await submitPayload({
      featureId: FeatureIDs.ConfidentialAssessment,
      payload: FINAL_PAYLOAD,
      edit,
    })
      .then((res) => {})
      .catch((e) => {
        setActionError({ type: 1, message: "Something went wrong" });
      })
      .finally(() => {});
  }

  useEffect(() => {
    getData({
      featureId: FeatureIDs.ConfidentialAssessment,
      payload: {
        "Data.AppraiseeUserID": EPP.AppraiseeUserID,
        "Data.EmployeePlanID": EPP.TransactionID,
      },
    }).then((res2) => {
      console.log(res2, "THIS IS CONFIDENTIAL ASSESSMENT");
      if (res2 && res2?.Data) setConfidentialPayload(res2.Data[0]);
    });
  }, []);

  useEffect(() => {
    function getFinalPeriodAssessment(data) {
      if (typeof data === "string" || !data) return undefined;

      let PeriodType = data[0]?.ReviewPeriodType;
      let ParameterAssessment = null;
      if (PeriodType) {
        ParameterAssessment = data.find(
          (assess) =>
            assess.ReviewPeriodType === PeriodType &&
            (PeriodType !== "I"
              ? assess.ReviewPeriodUnit === getFinalPeriod(PeriodType)
              : true)
        );
      }
      return ParameterAssessment;
    }

    function validateAssessment(a) {
      if (!a) return null;
      console.log(a, "adocprinted");
      const WorkflowDoc = {
        ConversationID: "",
        WorkflowID: "",
        WorkflowStatus: "",
        WorkflowSteps: "",
      };

      const Workflow = Object.keys(WorkflowDoc).reduce((total, current) => {
        total[current] = a[current];
        return total;
      }, {});

      if (Workflow && Workflow.WorkflowSteps) {
        const processor = new WorkflowProcesser(Workflow);
        if (
          processor.getCurrentStepData()?.StepOwnerLabel === "Appraiser" &&
          processor.getCurrentStepUserID() === getUserId()
        ) {
          if (!pendingWithAppraiser) {
            setPendingWithAppraiser(true);
          }
          console.log(
            processor.getCurrentStepData(),
            "processor.getCurrentStepData()"
          );
          return processor;
        }
      }
      return undefined;
    }

    if (EPP.Dimensions.length === 2) {
      getDimensionAssessmentDocs(EPP.Dimensions[0].DimensionID).then((res1) => {
        getDimensionAssessmentDocs(EPP.Dimensions[1].DimensionID).then(
          (res2) => {
            const data = {
              [EPP.Dimensions[0].DimensionID]: [],
              [EPP.Dimensions[1].DimensionID]: [],
            };

            const d1 = getFinalPeriodAssessment(res1.Data);
            const d2 = getFinalPeriodAssessment(res2.Data);

            const valid = [null, null];
            console.log(res1, res2, "res1.Data, res2.Data");
            const goalsAssessment = (
              typeof res1 === "string" ? [] : res1.Data
            ).find((d) => d.WorkflowStatus !== "C");
            const coreValues = (typeof res2 === "string" ? [] : res2.Data).find(
              (d) => d.WorkflowStatus !== "C"
            );

            const goalsProcessor = validateAssessment(goalsAssessment);
            const coreValuesProcessor = validateAssessment(coreValues);
            if (goalsProcessor) {
              valid[0] = {
                data: goalsAssessment,
                actionType:
                  goalsProcessor.getCurrentStepData()?.AllowedActions[0],
              };
            }
            if (coreValuesProcessor) {
              valid[1] = {
                data: coreValues,
                actionType:
                  coreValuesProcessor.getCurrentStepData()?.AllowedActions[0],
              };
            }
            console.log("VALIDASESSMENTYDOCUMENTS", valid);
            setAppraiserAssessments(valid);

            if (d1) {
              data[EPP.Dimensions[0].DimensionID].push(d1);
            }
            if (d2) {
              data[EPP.Dimensions[1].DimensionID].push(d2);
            }
            setFinalAssessments({
              ...data,
            });
          }
        );
      });
    }
  }, []);

  function getAppraiseeCondition() {
    const DimensionID = EPP.Dimensions[0].DimensionID;

    const assessments = FinalAssessments[DimensionID]; // Final period assess of Goals

    if (EPP.AppraiseeUserID === getUserId()) {
      if (assessments.length === 0) return false; // User will not see

      const doc = assessments[0];
      if (doc) {
        const WorkflowDoc = {
          ConversationID: "",
          WorkflowID: "",
          WorkflowStatus: "",
          WorkflowSteps: "",
        };
        const Workflow = Object.keys(WorkflowDoc).reduce((total, current) => {
          total[current] = doc[current];
          return total;
        }, {});

        if (Workflow.WorkflowStatus === "C") return true;
      }
      return false;
    } else {
      return true;
    }
  }

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function saveEPPConfidentialGoalsCoreValues() {
    try {
      setAppraiserLoading(true);
      await editEPP();
      await editConfidentialAssessment();
      if (appraiserAssessments[0]) {
        await editDimension(appraiserAssessments[0].data, undefined);
      }
      if (appraiserAssessments[1]) {
        await delay(4000);
        await editDimension(appraiserAssessments[1].data, undefined);
      }
      setAppraiserLoading(false);
      setActionError({
        type: 0,
        message: "Saved Plan Successfully",
      });
    } catch (e) {
      setActionError({
        type: 1,
        message: e,
      });
    }
  }

  function isValidAssessment(assessment) {
    const parameters = assessment?.Parameters;
    if (parameters) {
      for (let i = 0; i < parameters.length; i++) {
        const parameter = parameters[i];
        const index = parameter?.ActorAssessments?.findIndex(
          (a) => a.ActorType === "Appraiser"
        );
        if (index && index !== -1) {
          const data = parameter?.ActorAssessments[index];
          if (
            data.RatingScaleValueID === "" ||
            data.RatingScaleValueID === -1
          ) {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  const [modal, setModal] = useState(null);

  async function submitEPPConfidentialGoalsCoreValues() {
    try {
      if (EPP.AppraiserOverallComments) {
        setAppraiserLoading(true);
        if (appraiserAssessments[0] && appraiserAssessments[1]) {
          if (
            appraiserAssessments[0].data.ReviewPeriodUnit ===
            appraiserAssessments[1].data.ReviewPeriodUnit
          ) {
            if (
              isValidAssessment(appraiserAssessments[0].data) &&
              isValidAssessment(appraiserAssessments[1].data)
            ) {
              if (
                appraiserAssessments[0].data.ReviewPeriodUnit ===
                  getFinalPeriod(
                    appraiserAssessments[0].data.ReviewPeriodType
                  ) &&
                appraiserAssessments[1].data.ReviewPeriodUnit ===
                  getFinalPeriod(appraiserAssessments[1].data.ReviewPeriodType)
              ) {
                setModal({
                  Component: SubmitAlert,
                  props: {
                    message:
                      "Caution: Ensure thorough completion of all appraisal sections before proceeding. Once advanced to the next stage, editing will be restricted. Double-check your inputs to avoid any unintended consequences.",
                    closeAlert: () => {
                      setModal(null);
                    },
                    onCancel: () => {
                      setAppraiserLoading(false);
                      setModal(null);
                    },
                    onSubmit: async () => {
                      await editEPP();
                      await editConfidentialAssessment();
                      await editDimension(
                        appraiserAssessments[0].data,
                        appraiserAssessments[0].actionType
                      );
                      await delay(4000);
                      await editDimension(
                        appraiserAssessments[1].data,
                        appraiserAssessments[1].actionType
                      );
                      setActionError({
                        type: 0,
                        message: "Plan submitted successfully",
                      });
                      setModal(null);
                    },
                  },
                });
              }
            } else {
              setActionError({
                type: 1,
                message:
                  "Assessment is not completely done in one of the dimension! Saved Partial Plan Only",
              });
            }
          } else {
            setActionError({
              type: 1,
              message:
                "Assessments in both dimensions are not in same review period! Saved Partial Plan Only",
            });
          }
        } else {
          setActionError({
            type: 1,
            message:
              "Assessments not started in some dimensions! Saved Partial Plan Only",
          });
        }
        setAppraiserLoading(false);
      } else {
        setActionError({
          type: 1,
          message: "Appraiser Overall Comments are required",
        });
        setModal(null);
      }
    } catch (e) {
      setActionError({
        type: 1,
        message: e,
      });
    }
  }

  return (
    <>
      <Box
        display={"flex"}
        gap={"1em"}
        alignItems={"stretch"}
        flexWrap={"wrap"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap="1em"
          flexBasis={"30vw"}
          flexGrow={"0"}
          flexShrink={"0.3"}
        >
          <Box>
            {[
              ...Object.keys(AppraiseeSnapshotWidgetProps).map(
                (key) => document[key]
              ),
            ].includes("E") && (
              <AppraiseeSnapshot
                EPP={EPP}
                {...Object.keys(AppraiseeSnapshotWidgetProps).reduce(
                  (total, current) => {
                    total[current] = document[current];
                    return total;
                  },
                  {}
                )}
              />
            )}
          </Box>
          {Object.keys(FinalAssessments).length > 0 && (
            <Box>
              <PlanScores
                EPP={EPP}
                handleTabEdit={handleTabEdit}
                FinalAssessments={FinalAssessments}
                AggregatePlanScores={{
                  ...Object.keys(AggregatePlanScoreProps).reduce(
                    (total, current) => {
                      total[current] = document[current];
                      return total;
                    },
                    {}
                  ),
                }}
                ModeratedPlanScores={{
                  ...Object.keys(ModeratedPlanScoreProps).reduce(
                    (total, current) => {
                      total[current] = document[current];
                      return total;
                    },
                    {}
                  ),
                }}
                AssessorPlanScores={{
                  ...Object.keys(AssessorPlanScoreProps).reduce(
                    (total, current) => {
                      total[current] = document[current];
                      return total;
                    },
                    {}
                  ),
                }}
                AdjustedPlanScores={{
                  ...Object.keys(AdjustedPlanScoreProps).reduce(
                    (total, current) => {
                      total[current] = document[current];
                      return total;
                    },
                    {}
                  ),
                }}
              />
            </Box>
          )}
        </Box>

        {getUserId() !== EPP.AppraiseeUserID &&
          document.SummaryDimensionScores &&
          document.SummaryDimensionScores.map((dimension) => {
            return Object.values(dimension).includes("E") ? (
              <Box flexBasis={"25vw"} flexGrow={"1"}>
                <DimensionScore
                  EPP={EPP}
                  handleTabEdit={handleTabEdit}
                  DimensionScoreRules={dimension}
                  key={dimension.DimensionID}
                />
              </Box>
            ) : (
              <></>
            );
          })}
        {Object.keys(FinalAssessments).length > 0 &&
          FinalAssessments[EPP.Dimensions[0].DimensionID].length > 0 &&
          FinalAssessments[EPP.Dimensions[1].DimensionID].length > 0 &&
          getAppraiseeCondition() && (
            <Box flexBasis={"30vw"}>
              <OverallComments
                EPP={EPP}
                FinalAssessments={FinalAssessments}
                handleTabEdit={handleTabEdit}
              />
            </Box>
          )}
      </Box>
      <br />
      <br />
      {actionError && (
        <AlertMessage
          {...actionError}
          onClose={() => {
            setActionError(null);
          }}
          autoClose={true}
          autoCloseTime={3000}
        />
      )}
      <br />
      {pendingWithAppraiser && (
        <Box sx={{ display: "flex", gap: "1em" }}>
          <Button
            label={"Save Plan"}
            onClick={() => {
              saveEPPConfidentialGoalsCoreValues();
            }}
            isLoading={appraiserLoading}
          />
          <Button
            label={"Submit Plan"}
            onClick={() => {
              submitEPPConfidentialGoalsCoreValues();
            }}
            isLoading={appraiserLoading}
          />
        </Box>
      )}
      {modal && (
        <EwModal open={modal} onClose={() => {}}>
          <modal.Component {...modal.props} />
        </EwModal>
      )}
    </>
  );
};

export default Summary;
