export const rowToMapData = [
  "PlanPeriod",
  "AppraiseeUserEmployeeCode",
  "AppraiseeUserFullName",
  "CurrentStatus",
  "PlanStartDate",
  "PlanEndDate",
  "UserType",
  "HIDDENval",
];

export const heading = [
  "Plan Period",
  "Employee code",
  "Employee Name",
  "Plan Status",
  "Start Date",
  "End Date",
  "User Type",
  "HIDDENcol",
];
