import { Divider, Paper, Typography, Stack, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getEmployeeCount } from "../../../Utils/Common/Library";
import Molecule from "../../../MComponent/Molecule";
import TeamPlanTable from "./TeamPlanTable";
import useReduxStore from "../../../Utils/CustomHooks/ReduxUpdater";

const { Tabs } = Molecule;

const MyTeamPlan = () => {
  const [employees, setEmployees] = useState(null);
  const [store, setStore] = useReduxStore({
    id: "MYTEAMPLANS",
    initialState: { LR: null, FR: null, LRFR: null },
  });

  useEffect(() => {
    getEmployeeCount().then((res) => {
      setEmployees({
        FunctionalRelationCount: res.FunctionalRelationCount || 0,
        LineRelationCount: res.LineRelationCount || 0,
      });
    });
    return () => {
      setStore({}, "LR");
      setStore({}, "FR");
      setStore({}, "LRFR");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabList = [
    {
      label: "Appraiser",
      Component: TeamPlanTable,
      props: {
        relationshipType: "LR",
        employees,
        store,
        setStore,
      },
    },
    {
      label: "Reviewer",
      Component: TeamPlanTable,
      props: {
        relationshipType: "FR",
        employees,
        store,
        setStore,
      },
    },
    {
      label: "All",
      Component: TeamPlanTable,
      props: {
        employees,
        store,
        setStore,
      },
    },
  ];

  return !employees ? (
    <>Loading...</>
  ) : (
    <Box sx={{ width: "100%" }}>
      <Stack rowGap={"1rem"} sx={{ width: "80em", margin: "0 auto" }}>
        <Typography variant="h4">My Team Plan</Typography>
        <Divider />
        <Paper>
          <Tabs componentList={tabList} />
        </Paper>
      </Stack>
    </Box>
  );
};

export default MyTeamPlan;
