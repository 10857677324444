import React, { useEffect, useState } from "react";
import DataGrid2 from "../../../MComponent/Table/DataGrid2";
import { getDateFromEpoch, getUserId } from "../../../Utils/Common/Library";
import { useHistory } from "react-router-dom";
import { fetchAllData, generatePagination, generateUserType } from "./helpers";
import { heading, rowToMapData } from "./constants";

const TeamPlanTable = ({
  userLimit = 50,
  relationshipType = "",
  employees = { LineRelationCount: 0, FunctionalRelationCount: 0 },
  store = { LR: null, FR: null, LRFR: null },
  setStore,
}) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const history = useHistory();

  const role =
    relationshipType === "FR" ? "R" : relationshipType === "LR" ? "A" : "";

  useEffect(() => {
    let payloads = null;
    let partialRows = null;
    if (relationshipType === "LR") {
      if (store.LR && store.LR.length > 0) {
        setRows(store.LR);
      } else {
        payloads = [
          ...generatePagination(employees.LineRelationCount, "LR", userLimit),
        ];
      }
    } else if (relationshipType === "FR") {
      if (store.FR && store.FR.length > 0) {
        setRows(store.FR);
      } else {
        payloads = [
          ...generatePagination(
            employees.FunctionalRelationCount,
            "FR",
            userLimit
          ),
        ];
      }
    } else {
      if (store.LRFR && store.LRFR.length > 0) {
        setRows(store.LRFR);
      } else if (
        store.LR &&
        store.LR.length > 0 &&
        store.FR &&
        store.FR.length > 0
      ) {
        setRows([...store.LR, ...store.FR]);
        setStore([...store.LR, ...store.FR], "LRFR");
      } else if (store.LR && store.LR.length > 0) {
        partialRows = store.LR;
        payloads = [
          ...generatePagination(
            employees.FunctionalRelationCount,
            "FR",
            userLimit
          ),
        ];
      } else if (store.FR && store.FR.length > 0) {
        partialRows = store.FR;
        payloads = [
          ...generatePagination(employees.LineRelationCount, "LR", userLimit),
        ];
      } else {
        payloads = [
          ...generatePagination(employees.LineRelationCount, "LR", userLimit),
          ...generatePagination(
            employees.FunctionalRelationCount,
            "FR",
            userLimit
          ),
        ];
      }
    }

    if (payloads && payloads.length > 0) {
      setLoading(true);
      fetchAllData(payloads, role)
        .then((res) => {
          const mappedRows = res.map((item) => {
            const newItem = [];
            rowToMapData.forEach((rowField) => {
              if (rowField === "UserType") {
                newItem.push(generateUserType(getUserId(), item.Dimensions));
              } else if (rowField === "HIDDENval") {
                newItem.push(item);
              } else if (typeof rowField === "string") {
                newItem.push(item[rowField]);
              } else {
                newItem.push("");
              }
            });
            return newItem;
          });

          if (partialRows) {
            const newMappedRows = [...partialRows, ...mappedRows].sort(
              (a, b) => {
                if (a[0] < b[0]) return -1;
                if (a[0] > b[0]) return 1;
                return 0;
              }
            );
            setRows(newMappedRows);
            setStore(newMappedRows, "LRFR");
          } else {
            setRows(mappedRows);
            setStore(mappedRows, relationshipType || "LRFR");
          }
        })
        .catch(() => {
          setRows([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <>Loading Plans...</>
  ) : (
    <DataGrid2
      rows={rows}
      heading={heading}
      valueFormatter={{
        PlanStartDate: (val) => getDateFromEpoch({ epoch: val }),
        PlanEndDate: (val) => getDateFromEpoch({ epoch: val }),
      }}
      rowToFieldMap={rowToMapData}
      onRowClicked={({ data }) => {
        console.log(data.HIDDENval, "Hidden EPP Plan Data");
        const Plan = data.HIDDENval;
        if (!Plan.Dimensions) Plan.Dimensions = [];
        if (!Plan.Sections) Plan.Sections = [];
        history.push({ pathname: "/pms", state: { Plan } });
      }}
    />
  );
};

export default TeamPlanTable;
